.Sidemenu {
    min-width: 300px;
    height: 100%;
    background-color: #4a4a4a;
    padding: 30px 20px;

    .Buttons {
	margin-top: 40px;

	> a {
	    margin: 15px;
	    text-decoration: none;
	    color: white;
	    opacity: 0.8;

	    &:hover {
		opacity: 1;
	    }
	}

	.Disabled {
	    opacity: 0.5;

	    &:hover {
		opacity: 0.5;
	    }

	    small {
		color: red;
	    }
	}
    }

    .WhiteLogo {
	max-width: 200px;
    }

}
