.Wrapper {
    margin-top: 24px;
    margin-bottom: 54px;
    padding-left: 35px;
    padding-right: 30px;
}

.Name {
    font-weight: bold;
    font-size: 32px;
    color: #4d4d4d;
    margin-block-start: 0;
    margin-block-end: 0;
}

.SectionHeader {
    font-size: 18px;
    font-weight: bold;
    color: #4d4d4d;
}
