.PlanningPreview {
    margin: 50px;

    @at-root a {
	text-decoration: none;
    }

    @at-root a:hover {
	text-decoration: underline;
	color: #4a4a4a;
    }

}
