.MultiStateWrapper {
    margin: 25px 0;
}

.Option {
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 16px;
}

.Active {
    background-color: #e4e4e4;
}
