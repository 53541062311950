.Wrapper {
    margin-top: 24px;
    margin-bottom: 54px;
    padding-left: 35px;
    padding-right: 30px;
}

.Name {
    font-weight: bold;
    font-size: 32px;
    color: #4d4d4d;
    margin-block-start: 0;
    margin-block-end: 0;
}

.Process {
    font-weight: 300;
    color: #999999;
    font-size: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.Description {
    margin-block-start: 0;
    margin-block-end: 0;

}

.Observations {
    margin-block-start: 0;
    margin-block-end: 0;

}

.SectionHeader {
    font-size: 18px;
    font-weight: bold;
    color: #4d4d4d;
}


.SectionContent {
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 300;
    line-height: 1.6em;
}

.Creation {
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 300;
}

.TagsDisplay {
    margin: 25px 0;

    svg {
	fill: #cccccc;
	transform: rotate(90deg);
	cursor: auto;
    }

    .Tag {
	color: #999999;
	padding: 0 5px;
    }

}

.Actions {
    margin-bottom: 25px;
}

.State {
    font-size: 14px;
    background-color: #ccc;
    color: #fff;
    padding: 4px 8px;
    border-radius: 2px;
    margin-right: 8px;
    letter-spacing: 0.25px;
}
