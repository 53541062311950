.RecurrenceTable {

    a {
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;

	&:hover {
	    text-decoration: underline;
	}
    }
}
