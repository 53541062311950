@import '../../mixins.scss';


.Description {
    @include TruncateText;
    max-width: calc(100vw / 7);
}

.GoalsFilters {
    background-color: #fafafa;
    padding: 20px;

    div {
	padding-right: 50px;
    }
}

.GoalName {
    cursor: pointer;
    color: #4a4a4a;
    text-decoration: none;

    &:hover {
	text-decoration: underline;
    }
}
