.AppToolbar{
    a {
	color: #fff;
    }
}

.SectionDesktop {
    display: flex;
}

.Title {
    display: block;
}

.Search {
    position: relative;
    margin-left: 0;
    width: 100%;
}

.SearchIcon {
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Grow {
    flex-grow: 1;
}

.InputRoot {
    color: inherit;
    width: 100%;
}
