.Bookmarked {
    background-color: yellow;
}

.Selected {
    color: green;
}

.Centered {
    text-align: center;
}

.PlanningWeek {
    margin-block-end: 0;
}


.AllocationStats {
    font-size: 12px;
    color: #4a4a4a;
    margin-bottom: 10px;
}

table.PlanningTable  {
    font-size: 14px;
    color: #4a4a4a;
    border: 1px solid red;

    tr {
    	border: 1px solid red;
    }

    td {
    	border: 1px solid red;
    }

    th {
    	border: 1px solid red;
    }
}

.Saving {
    color: #4a4a4a;
    font-size: 11px;
}
