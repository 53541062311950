.Login {
    margin-top: 250px;
    width: 350px;

    div {
	padding-bottom: 15px;
    }

    .ErrorMessage {
	color: red;
	font-size: 13px;
    }
}
