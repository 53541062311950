.LogoutWrapper {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #4a4a4a;

    .Logout {
	min-width: 350px;
	max-width: 350px;

	h1 {
	    font-weight: 300;
	    text-align: center;
	    opacity: .6;
	    letter-spacing: 1px;
	}


	p {
	    text-align: center;
	    color: rgb(102, 102, 102);
	    opacity: 0.8;
	    margin-top: 20px;
	    line-height: 24px;
	}

	.Submit {
	    margin: 25px 0;
	    width: 100%;
	}

    }

}
